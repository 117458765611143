import React from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import PortableText from './../portableText'
import { responsiveTitle1 } from './../typography.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './management.module.css'

const query = graphql`
  query managementPage {
    board: sanityManagement(_id: { regex: "/(drafts.|)management/" }) {
      _rawIntro
      _rawIntroForGroups
      title
      boardMembers {
        nameOfCurrent
        email
        mobile
        role
        _rawRoleDetail
        image {
          asset {
            gatsbyImageData(width: 150, sizes: "(min-width:675px) 25vw, 70vw")
            altText
            url
          }
        }
      }
      boardGroups {
        groupName
        _rawActivities
        _rawGoals
        _rawGuide
      }
    }
  }
`

const Managemenet = props => {
  const board = useStaticQuery(query)
  const { title, _rawIntro, _rawIntroForGroups, boardMembers, boardGroups } = board.board || {}

  return (
    <>
      <h1 className={responsiveTitle1}>{title}</h1>
      <p className={styles.intro}>
        <PortableText blocks={_rawIntro} />
      </p>
      {boardMembers &&
        boardMembers.map(d => (
          <section className={styles.flex}>
            <section style={{ flex: '4' }}>
              <h3>{d.nameOfCurrent}</h3>
              <span>{d.role} </span>
              {d.mobile && (
                <>
                  <span> | </span> <span>{`${d.mobile}+`}</span>{' '}
                </>
              )}
              {d.email && (
                <>
                  <span> | </span> <span>{d.email}</span>{' '}
                </>
              )}
              <div style={{ maxWidth: '68ch', paddingInlineEnd: '1.4em' }}>
                <PortableText blocks={d._rawRoleDetail} />
              </div>
            </section>
            {d.image && d.image.asset.gatsbyImageData && (
              <div>
                <GatsbyImage
                  src={d.image.asset.url}
                  image={d.image.asset.gatsbyImageData}
                  alt={d.image.asset.altText}
                />
              </div>
            )}
          </section>
        ))}
      <hr></hr>
      <hr></hr>
      <hr></hr>
      <hr></hr>
      <hr></hr>
      {boardGroups && (
        <section style={{ maxWidth: '67ch' }}>
          <PortableText blocks={_rawIntroForGroups} />
        </section>
      )}
      {boardGroups &&
        boardGroups.map(d => (
          <>
            <Accordion className={styles.accordionWrapper}>
              <AccordionItem className={styles.accordionItem}>
                <AccordionItemHeading>
                  <AccordionItemButton className={styles.accordionButton}>
                    {d.groupName}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                  <section>
                    <h3>الأهداف</h3> <PortableText blocks={d._rawGoals} />
                    <h3>الأنشطة</h3> <PortableText blocks={d._rawActivities} />
                    <h3>الآلية المقترحة لعمل اللجنة</h3> <PortableText blocks={d._rawGuide} />
                  </section>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </>
        ))}
    </>
  )
}

export default Managemenet
