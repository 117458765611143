import React from 'react'
import { Link } from 'gatsby'
import Container from '../container'
import * as styles from './breadcrumb.module.css'

function Breadcrumb({ activeLink = false, currentNavItems = [] }) {
  const navStack = [{ name: 'الرئسية', slug: '' }, ...currentNavItems]

  return (
    <>
      {currentNavItems.length > 0 && (
        <div className={styles.miniNav}>
          {navStack.map((stackItem, ind) => {
            if (!activeLink && ind === navStack.length - 1)
              return <span className={styles.tail}>{stackItem.name}</span>
            return (
              <React.Fragment>
                <Link className={styles.anchorNav} to={`/${stackItem.slug}`}>
                  {stackItem.name}
                </Link>
                <span>\</span>
              </React.Fragment>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Breadcrumb
