import React from 'react'
import Layout from './../containers/layout'
import Container from '../components/container'
import Management from '../components/pages/management'
import Breadcrumb from '../components/layout/breadcrumb'
import SEO from '../components/seo'

const ManagementPage = props => {
  let navItemStack = [{ name: 'الإدارة', slug: 'management' }]
  return (
    <Layout>
      <Container>
        <SEO title="الإدارة" keywords={['management', 'الرئيس', 'الجتماع', 'العمومي']} />
        <Breadcrumb currentNavItems={navItemStack} />
        <Management />
      </Container>
    </Layout>
  )
}

export default ManagementPage
